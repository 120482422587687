import React, { useEffect, useState } from 'react';
import Header from '../../Shared/Header';
import BreadCums from '../../Shared/BreadCums';
import Form from './Form';
import Apps from '../../Shared/Apps';
import Footer from '../../Shared/Footer';
import { useParams } from 'react-router';
import axios from 'axios';

const Edit = () => {
    const params = new URLSearchParams(window.location.search)
    const jwt = params.get('jwt')

    const [participant, setParticipant] = useState(null)

    const loadParticipant = async () => {
        const data = await axios.get(`https://api-s.arena.promo/api/participant-info?jwt=${jwt}`)
        setParticipant(data?.data)
    }

    useEffect(() => {
        loadParticipant()
    }, [jwt])

    const name = participant?.participant?.name ? participant?.participant?.name : participant?.name

    return (
        <>
            <Header
                headerClass='sc-header-section inner-header-style'
                signUpBtn='inner-btn'
            />
            <BreadCums pageTitle={`Форма обовʼязкових елементів ${name }`} />

            {!!participant && (
                <Form participant={participant} />
            )}
            <Footer />
        </>
    );
};

export default Edit;
