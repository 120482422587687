import React, { useEffect, useState } from 'react';
import Header from '../../Shared/Header';
import BreadCums from '../../Shared/BreadCums';
import Data from './Data';
import Footer from '../../Shared/Footer';
import { useParams } from 'react-router';
import axios from 'axios';


const Votes = () => {
    const props = useParams();

    const [participant, setParticipant] = useState(null)

    const loadParticipant = async () => {
        const data = await axios.get(`https://api-s.arena.promo/api/participant/${props.scope}/${props.type}/${props.id}/${props.timestamp}`)
        setParticipant(data?.data)
    }

    useEffect(() => {
        loadParticipant()
    }, [props])

    return (
        <>
            <Header
                headerClass='sc-header-section inner-header-style'
                signUpBtn='inner-btn'
            />
            <BreadCums pageTitle={`Оцінки ${participant?.participant?.name}`} />
            <Data participant={participant} />
            <Footer />
        </>
    );
};

export default Votes;
