import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router';

const EditProposal = ({participant, jwt}) => {
    const props = useParams();

    const [data, setData] = useState({})

    useEffect(() => {
        setData(participant)
    }, [participant])

    const handleMessage = async (event) => {
        event.preventDefault()

        const payload = await axios.post(`https://api-s.arena.promo/api/participant-info?jwt=${jwt}`, data)

        if(payload?.data?.id){
            toast("Успішно змінено.", {type: 'success', autoClose: false})
            alert('Дані успішно збережено. Будь-ласка перевірте правильність оновлених даних в телеграм боті.');
        }else{
            toast("Вибачте, сталась помилка. Перезавантажте сторінку і спробуйте ще раз.", {type: 'error', autoClose: false})
        }
    }

    return (
        <>
            <Helmet>
                <meta name="robots" content="noindex, nofollow" />
            </Helmet>
            <section>
                <div className="sc-contact-pages-area sc-pt-140 sc-md-pt-70 sc-pb-20 sc-md-pb-10">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <div className="contact-box sc-md-mb-5">
                                    <form onSubmit={handleMessage}>
                                        <div className="login-form">
                                            <div className="form-box">
                                                <label className="sc-mb-10">ПІБ учасника(ів) / Назва колективу*</label>
                                                <input
                                                    className="from-control"
                                                    type="text"
                                                    placeholder="ПІБ учасника(ів) / Назва колективу"
                                                    value={data.name}
                                                    onChange={e => setData({...data, name: e.target.value})}
                                                    required
                                                    autoComplete="off"
                                                 />
                                            </div>
                                            <div className="form-box">
                                                <label className="sc-mb-10">Телефон*</label>
                                                <input
                                                    className="from-control"
                                                    type="text"
                                                    placeholder="Телефон"
                                                    value={data.phone}
                                                    onChange={e => setData({...data, phone: e.target.value})}
                                                    required
                                                    autoComplete="off"
                                                 />
                                            </div>
                                            <div className="form-box">
                                                <label className="sc-mb-10">Адреса*</label>
                                                <input
                                                    className="from-control"
                                                    type="text"
                                                    placeholder="Адреса"
                                                    value={data.address}
                                                    onChange={e => setData({...data, address: e.target.value})}
                                                    autoComplete="off"
                                                 />
                                            </div>
                                            <div className="form-box">
                                                <label className="sc-mb-10">Тренер*</label>
                                                <input
                                                    className="from-control"
                                                    type="text"
                                                    placeholder="Тренер"
                                                    value={data.coach}
                                                    onChange={e => setData({...data, coach: e.target.value})}
                                                    autoComplete="off"
                                                 />
                                            </div>
                                            <div className="form-box">
                                                <label className="sc-mb-10">Школа/Студія/Коллектив*</label>
                                                <input
                                                    className="from-control"
                                                    type="text"
                                                    placeholder="Школа/Студія/Коллектив"
                                                    value={data.school}
                                                    onChange={e => setData({...data, school: e.target.value})}
                                                    autoComplete="off"
                                                 />
                                            </div>
                                            <div className="form-box">
                                                <label className="sc-mb-10">Назва номеру*</label>
                                                <input
                                                    className="from-control"
                                                    type="text"
                                                    placeholder="Назва номеру"
                                                    value={data.title}
                                                    onChange={e => setData({...data, title: e.target.value})}
                                                    autoComplete="off"
                                                 />
                                            </div>


                                            {(participant?.tournament?.definition_participant_additional_parameters || [])
                                                .filter(p => p.type === 'buttons')
                                                .filter(p => !p.conditions || !(p.conditions || []).map(c => c.value.includes((data?.parameters || {})[c.key])).filter(v => !v).length)
                                                .map(param => (
                                                <div className="form-box" key={param.key}>
                                                    <label className="sc-mb-10">{param.name}</label>
                                                    <select
                                                        className="sc-select"
                                                        value={(data?.parameters || {})[param.key] || ''}
                                                        label={param.name}
                                                        onChange={type => setData({...data, parameters: {...data?.parameters, [param.key]: type.target.value}})}
                                                    >
                                                        {Object.keys(param.values).map(k => (
                                                            <option key={k} value={k}>{param.values[k]}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                            ))}

                                            {(participant?.tournament?.definition_participant_additional_parameters || [])
                                              .filter(p => p.type === 'message')
                                              .filter(p => !p.conditions || !(p.conditions || []).map(c => c.value.includes((data?.parameters || {})[c.key])).filter(v => !v).length)
                                              .map(param => (
                                                <div className="form-box" key={param.key}>
                                                    <label className="sc-mb-10">{param.name}</label>
                                                    <input
                                                        className="from-control"
                                                        type="text"
                                                        placeholder={param.name}
                                                        value={(data?.parameters || {})[param.key] || ''}
                                                        onChange={type => setData({...data, parameters: {...data?.parameters, [param.key]: type.target.value}})}
                                                        autoComplete="off"
                                                     />
                                                </div>
                                            ))}
                                        </div>
                                        <div className="submit-button">
                                            <input className="submit-btn" type="submit" value="Відправити" />
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default EditProposal;
