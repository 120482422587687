import React, { useState } from 'react';
import { toast } from 'react-toastify';
import axios from 'axios';

const ContactInfo = () => {
    const [ price, setPrice ] = useState('252')
    const [ date, setDate ] = useState('')
    const [ dob, setDob ] = useState('')
    const [ dob_child, setDobChild ] = useState('')
    const [ firstname, setFirstname ] = useState('')
    const [ lastname, setLastname ] = useState('')
    const [ surname, setSurname ] = useState('')
    const [ phone, setPhone ] = useState('')

    const [ name_child, setNameChild ] = useState('')
    const [ city, setCity ] = useState('')
    const [ street, setStreet ] = useState('')
    const [ number, setNumber ] = useState('')
    const [ flat, setFlat ] = useState('')
    const [ personal_number, setPersonalNumber ] = useState('')
    const [ passport, setPassport ] = useState('')

    const [ email, setEmail ] = useState('')
    const [ passport_child, setPassportChild ] = useState('')
    const [ personal_number_child, setPersonalNumberChild ] = useState('')


    const handleMessage = async (event) => {
        event.preventDefault()

        toast("Заявку на страхувальний поліс створено.", {type: 'success', autoClose: false})

        const data = await axios.post('https://api-s.arena.promo/api/insurance/proposals', {
            price: price,
            date: date,
            firstname: firstname,
            name_child: name_child,
            dob_child: dob_child,
            lastname: lastname,
            surname: surname,
            phone: phone,
            dob: dob,
            city: city,
            street: street,
            number: number,
            flat: flat,
            personal_number: personal_number,
            passport: passport,
            email: email,
            passport_child: passport_child,
            personal_number_child: personal_number_child,
        })

        if(data?.data?.id){
            event.target.reset()
        }
    }

    return (
        <>
            <section>
                <div className="sc-contact-pages-area sc-pt-140 sc-md-pt-70 sc-pb-20 sc-md-pb-10">
                    <div className="container">
                        <div className="row align-items-start">
                            <div className="col-lg-6">
                                <div className="sc-heading-area sc-mb-20">
                                    <span className="sc-sub-title primary-color">Придбати страхувальний поліс</span>
                                    <h2 className="heading-title sc-mb-45">Страхова компанія "Оранта"</h2>
                                </div>
                                <p>Дана послуга не повʼязана з жодним з організаторів змагань і надається партнерською компанією.</p>
                                <p>Будь-ласка, залиште ваші контактні дані. Страховий агент звʼяжеться з вами у найближчий час.</p>
                                <p>Оберіть суму страхового платежу:</p>
                                <div className="contact-page-service-box sc-md-mb-25">
                                    <div className="contact_icon">
                                        <label>
                                            <input type="radio" value="250" name="price" defaultChecked onChange={e => setPrice(e.target.value)} />
                                            &nbsp;250грн - страхова сума 22,000грн (3 дні)
                                        </label>
                                        <label>
                                            <input type="radio" value="348" name="price" onChange={e => setPrice(e.target.value)} />
                                            &nbsp;348грн - страхова сума 30,000грн (3 дні)
                                        </label>
                                    </div>
                                    <div className="contact-service-box">

                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-6">
                                <div className="contact-box sc-md-mb-5">
                                    <form onSubmit={handleMessage}>
                                        <div className="login-form">
                                            <div className="form-box">
                                                <label className="sc-mb-10">Дата турніру/фестивалю. Ообирайте дату початку події, страховий поліс буде дійсний в цей день + два наступні.*</label>
                                                <input className="from-control" type="date" id="date" name="date" placeholder="Оберіть дату" onChange={e => setDate(e.target.value)} required autoComplete="off" />
                                            </div>
                                            <h3>Страхувальник/платник по полісу</h3>
                                            <div className="form-box">
                                                <label className="sc-mb-10">Імʼя платника*</label>
                                                <input className="from-control" type="text" id="firstname" name="firstname" placeholder="Напишіть імʼя платника" onChange={e => setFirstname(e.target.value)} required autoComplete="off" />
                                            </div>
                                            <div className="form-box">
                                                <label className="sc-mb-10">Прізвище платника*</label>
                                                <input className="from-control" type="text" id="lastname" name="lastname" placeholder="Напишіть прізвище платника" onChange={e => setLastname(e.target.value)} required autoComplete="off" />
                                            </div>
                                            <div className="form-box">
                                                <label className="sc-mb-10">По-батькові платника*</label>
                                                <input className="from-control" type="text" id="surname" name="surname" placeholder="Напишіть по-батькові платника" onChange={e => setSurname(e.target.value)} required autoComplete="off" />
                                            </div>
                                            <div className="form-box">
                                                <label className="sc-mb-10">Дата народження платника*</label>
                                                <input className="from-control" type="date" placeholder="Дату народження платника" onChange={e => setDob(e.target.value)} required autoComplete="off" />
                                            </div>
                                            <div className="form-box">
                                                <label className="sc-mb-10">Номер та серія паспорту або ID картки платника.*</label>
                                                <input className="from-control" type="text" placeholder="Документ (паспорт, ID картка) платника" onChange={e => setPassport(e.target.value)} required autoComplete="off" />
                                            </div>
                                            <div className="form-box">
                                                <label className="sc-mb-10">ІНН платника*</label>
                                                <input className="from-control" type="text" placeholder="ІНН платника" onChange={e => setPersonalNumber(e.target.value)} required autoComplete="off" />
                                            </div>
                                            <h3>Адреса прописки платника</h3>
                                            <div className="form-box">
                                                <label className="sc-mb-10">Місто*</label>
                                                <input className="from-control" type="text" placeholder="Напишіть ваше місто" onChange={e => setCity(e.target.value)} required autoComplete="off" />
                                            </div>
                                            <div className="form-box">
                                                <label className="sc-mb-10">Вулиця*</label>
                                                <input className="from-control" type="text" placeholder="Напишіть вашу вулицю" onChange={e => setStreet(e.target.value)} required autoComplete="off" />
                                            </div>
                                            <div className="form-box">
                                                <label className="sc-mb-10">Номер дому*</label>
                                                <input className="from-control" type="text" placeholder="Напишіть ваш номер дому" onChange={e => setNumber(e.target.value)} required autoComplete="off" />
                                            </div>
                                            <div className="form-box">
                                                <label className="sc-mb-10">Номер квартири</label>
                                                <input className="from-control" type="text" placeholder="Напишіть ваш номер квартири" onChange={e => setFlat(e.target.value)} autoComplete="off" />
                                            </div>
                                            <div className="form-box">
                                                <label className="sc-mb-10">Контактний номер телефону (на нього буде приходити посилання на оплату по посиланню, та код підтвердження полісу). Посилання дійсне до 24:00 дати отримання*</label>
                                                <input className="from-control" type="text" id="phone" name="phone" placeholder="Напишіть ваш номер телефону" onChange={e => setPhone(e.target.value)} required autoComplete="off" />
                                            </div>
                                            <div className="form-box">
                                                <label className="sc-mb-10">Електронна пошта (на неї після оплати приходить поліс)</label>
                                                <input className="from-control" type="text" placeholder="Напишіть ваш email" onChange={e => setEmail(e.target.value)} autoComplete="off" />
                                            </div>
                                            <h3>Застрахована особа</h3>
                                            <div className="form-box">
                                                <label className="sc-mb-10">ПІБ застрахованої особи</label>
                                                <input className="from-control" type="text" id="name_child" name="name_child" placeholder="Напишіть ПІБ застраховканої особи" onChange={e => setNameChild(e.target.value)} autoComplete="off" />
                                            </div>
                                            <div className="form-box">
                                                <label className="sc-mb-10">Дата народження застрахованої особи</label>
                                                <input className="from-control" type="date" placeholder="Оберіть дату народження застраховканої особи" onChange={e => setDobChild(e.target.value)} autoComplete="off" />
                                            </div>
                                            <div className="form-box">
                                                <label className="sc-mb-10">Номер та серія паспорту або ID картки, або свідоцтво про народження застрахованої особи</label>
                                                <input className="from-control" type="text" placeholder="Документ (паспорт, ID картка) застраховканої особи" onChange={e => setPassportChild(e.target.value)} required autoComplete="off" />
                                            </div>
                                            <div className="form-box">
                                                <label className="sc-mb-10">ІНН застрахованої особи(за наявності)</label>
                                                <input className="from-control" type="text" placeholder="Документ (паспорт, ID картка) застрахованої особи" onChange={e => setPersonalNumberChild(e.target.value)} autoComplete="off" />
                                            </div>


                                        </div>
                                        <div className="submit-button">
                                            <input className="submit-btn" type="submit" value="Відправити" />
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default ContactInfo;
