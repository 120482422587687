import React, { useEffect, useState } from 'react';
import Header from '../../Shared/Header';
import BreadCums from '../../Shared/BreadCums';
import Data from './Data';
import Footer from '../../Shared/Footer';
import { useParams } from 'react-router';
import axios from 'axios';


const AllVotes = () => {
    const props = useParams();

    const [participants, setParticipants] = useState([])

    const loadParticipants = async () => {
        const data = await axios.get(`https://api-s.arena.promo/api/telegram/${props.scope}/${props.tournament}/${props.telegram}/${props.timestamp}`)
        setParticipants(data?.data)
    }

    useEffect(() => {
        loadParticipants()
    }, [props])

    return (
        <>
            <Header
                headerClass='sc-header-section inner-header-style'
                signUpBtn='inner-btn'
            />
            {participants.map(participant => (
                <>
                    <BreadCums pageTitle={`Оцінки ${participant?.participant?.name}`} />
                    <Data participant={participant} />
                </>
            ))}
            <Footer />
        </>
    );
};

export default AllVotes;
