import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router';
import Select from 'react-select';

const FormElements = ({participant}) => {
    const params = new URLSearchParams(window.location.search)
    const jwt = params.get('jwt')

    const [ data, setData ] = useState([])
    const [ combinations, setCombinations ] = useState([])
    const [ elements, setElements ] = useState([])
    const [ selectedElement, setSelecteElement ] = useState({})
    const [ selectedCombinations, setSelectedCombinations ] = useState([])
    const [ combinationAdb, setCombinationAdb ] = useState(false)

    useEffect(() => {
        setData((participant?.posa_form_elements?.form || []).map(e => ({
            value: e.code,
            label: `${e.code} ${e.element} ${e.value}`,
            element: e
        })))
        setCombinations((participant?.posa_form_elements?.combinations || []).map(combination => combination.map(e => ({
            value: e.code,
            label: `${e.code} ${e.element} ${e.value}`,
            element: e
        }))))
    }, [participant])

    const loadElements = async () => {
        const suffix = (participant?.parameters['1715054651406'] && participant?.parameters['1715054651406'] !== 'null') ? 'pole' : 'aerial'

        const data = await axios.get(`https://api-s.arena.promo/api/posa/${suffix}`)
        setElements([{value: null, label: 'Оберіть код'}].concat(data?.data.map(e => ({
            value: e.code,
            label: `${e.code} ${e.element} ${e.value}`,
            element: e
        }))))
    }

    useEffect(() => {
        loadElements()
    }, [])

    const removeCode = (i) => {
        setData(data.filter((d, j) => j !== i))
    }

    const removeCombination = (i) => {
        setCombinations(combinations.filter((d, j) => j !== i))
    }

    const addCode = () => {
        if(!selectedElement){
            return
        }

        setData([...data, selectedElement])
        setSelecteElement({})
    }

    const addCombination = () => {
        if(!selectedCombinations?.length){
            return
        }

        const existsAdb = combinations.find(c => c[0].element?.adb)
        if(combinationAdb && existsAdb){
            toast("Вибачте, Super Bonus можна використати лише один раз.", {type: 'error'})
            return
        }

        setCombinations([...combinations, selectedCombinations.map(c => ({...c, element: {...c.element, adb: combinationAdb}}))])
        setSelectedCombinations([])
        setCombinationAdb(false)
    }

    const handleSave = async (event) => {
        event.preventDefault()

        const payload = await axios.post(`https://api-s.arena.promo/api/posa/form?jwt=${jwt}`, {
            form: data.map(d => d.element),
            combinations: combinations.map(d => d.map(v => v.element)),
        })

        if(payload?.data?.id){
            toast("Дані успішно збережено.", {type: 'success', autoClose: false})
            //alert('Дані успішно збережено. Будь-ласка перевірте правильність оновлених даних в телеграм боті.');
        }else{
            toast("Вибачте, сталась помилка. Перезавантажте сторінку і спробуйте ще раз.", {type: 'error', autoClose: false})
        }
    }

    return (
        <>
            <Helmet>
                <meta name="robots" content="noindex, nofollow" />
            </Helmet>
            <section>
                <div className="sc-contact-pages-area sc-pt-40 sc-md-pt-20 sc-pb-20 sc-md-pb-10">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-12" style={{padding: '0px'}}>
                                <div style={{width: '100%', padding: '5px'}}>
                                    <h3>Обрані елементи</h3>
                                    <table style={{border: '1px solid grey', width: '100%'}}>
                                        {data.map((row, i) => (
                                            <tr key={row.element.code}>
                                                <td><b>{i + 1}</b></td>
                                                <td>{row.element.code}</td>
                                                <td>{row.element.element}</td>
                                                <td>{row.element.value}</td>
                                                <td>
                                                    {!participant?.posa_form_elements?.approved && (
                                                        <span onClick={() => removeCode(i)}>❌</span>
                                                    )}
                                                </td>
                                            </tr>
                                        ))}
                                    </table>
                                </div>

                                {!participant?.posa_form_elements?.approved && (
                                    <div className="contact-box" style={{padding: '5px'}}>
                                          <div style={{zIndex: 999, position: 'relative'}}>
                                              <label className="sc-mb-10">Додати елемент за кодом*</label>
                                              <Select
                                                  className="sc-select"
                                                  value={selectedElement}
                                                  onChange={value => setSelecteElement(value)}
                                                  options={elements}
                                              />
                                          </div>

                                          <div className="submit-button" style={{backgroundColor: '#ff7f00', zIndex: 1}}>
                                              <input className="submit-btn" type="button" value="Додати код" onClick={() => addCode()} />
                                          </div>
                                    </div>
                                )}

                                <div style={{width: '100%', padding: '5px'}}>
                                    <h3>Обрані комбінації</h3>
                                    <table style={{border: '1px solid grey', width: '100%'}}>
                                        {combinations.map((row, i) => (
                                            <tr key={i}>
                                                <td>{row.map(e => e.element?.code).join(', ')}</td>
                                                <td>{row.map(e => e.element?.element).join(' + ')}</td>
                                                <td align="right">
                                                    {row[0].element.adb && ('Super Bonus  ')}
                                                    {!participant?.posa_form_elements?.approved && (
                                                        <span onClick={() => removeCombination(i)}>❌</span>
                                                    )}
                                                    &nbsp;
                                                </td>
                                            </tr>
                                        ))}
                                    </table>
                                </div>

                                {!!participant?.posa_form_elements?.approved && (
                                    <p>Елементи вже перевірені та затверджені організатором. Ви не можете їх зараз змінити. Зверніться, будь-ласка, до організатора.</p>
                                )}

                                {!participant?.posa_form_elements?.approved && (
                                    <div className="contact-box" style={{padding: '5px'}}>
                                        <div style={{zIndex: 899, position: 'relative'}}>
                                            <label className="sc-mb-10">Додати комбінацію</label>
                                            <Select
                                                className="sc-select"
                                                isMulti
                                                value={selectedCombinations}
                                                onChange={value => setSelectedCombinations(value)}
                                                options={data}
                                            />
                                        </div>

                                        <div>
                                            <label className="sc-mb-10">
                                                <input type="checkbox" checked={combinationAdb} onClick={e => setCombinationAdb(e.target.checked)} />&nbsp;&nbsp;&nbsp;Additional Difficulty Bonus/Super Bonus
                                            </label>
                                        </div>

                                        <div className="submit-button" style={{backgroundColor: '#ff7f00', zIndex: 1}}>
                                            <input className="submit-btn" type="button" value="Додати комбінацію" onClick={() => addCombination()} />
                                        </div>
                                    </div>
                                )}

                                {!participant?.posa_form_elements?.approved && (
                                    <form onSubmit={handleSave}>
                                        <div className="submit-button" style={{zIndex: 1}}>
                                            <input className="submit-btn" type="submit" value="Зберегти форму" />
                                        </div>
                                    </form>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default FormElements;
